@import "components/UI/styles/palette";

.ui-tag {
  &.ant-tag {
    font-weight: 600;
    line-height: 18px;
    font-size: 12px;
    border-radius: 37px;
    padding: 2px 8px;
    color: #000;
    border: none;
  }

  &.ui-tag--default {
    color: $grey-color-7;
    background-color: $grey-color-6;
  }
  &.ui-tag--green {
    color: $green-color;
    background-color: $green-color-2;
  }
  &.ui-tag--orange {
    color: $orange-color;
    background-color: $orange-color-1;
  }
  &.ui-tag--red {
    color: $red-color;
    background-color: $red-color-1;
  }
  &.ui-tag--blue {
    color: $cyan-color;
    background-color: $cyan-color-5;
  }
}
