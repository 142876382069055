@import "components/UI/styles/mixins.scss";

.replies-table {
  position: relative;
  height: 100%;

  &-head {
    display: flex;
    justify-content: space-between;
    &-transfer-btn {
      margin: 15px 0;
    }
  }

  &-column {
    &-address {
      width: 270px;
      min-width: 270px;
    }

    &-info {
      width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-tags {
      @include flex-block(center, space-between);
    }
  }

  &-spinner {
    @include position-absolute-center;
  }

  &-link-dropdown-trigger {
    cursor: pointer;
  }
}
