@import "components/UI/styles/mixins.scss";

.ui-copy {
  @include flex-block(center, space-evenly);

  &-btn {
    margin-left: 4px;
    cursor: pointer;
    transition: transform 0.2s ease-out;
  }
  &-btn:active {
    transform: scale(0.9);
  }
}
